import React, { useContext, useEffect, useRef, useState } from "react";
import NavBar, { LiteNavBar } from "../../NavBar";
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Constants, { EditProfileMenu, FilterOptions, commonPopupButtonNames, commonPopupTitleNames } from "../../../Constants/constants";
import Pusher from "pusher-js";
import config from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { dashboardProfileData } from "../../../Store/DashboardAPI/Action";
import RouteSegments, { RouteHelper } from "../../../Routes/RouteSegment";
import { ResetUnReadCount, UnreadMsgCount, getChatList, getConversationList, getCustomerServiceConversation, getGroupChatList, groupMessageReceived, messageReceived, reactionReceived, readbyReceived } from "../../../Store/Chats/Action";
import NotificationReceivedSound from "../../../sounds/notification-received.mp3";
import { getDeviceInfo, getGroupAuthId } from "../utils";
import { LoginContext } from "../../../Contexts/LoginContext";
import EndPoints from "../../../Constants/EndPoints";
import { GET, POST } from "../../../Services/api_services";
import { CommonNotificationAlertPopup, CommonNewPopup, CommonQualityPopup, KalyanRewardsPopup, RankingStartPopup, UploadPhotoPopuplite, CommonCastPopup, StarRaasiPopup, AstroPopup, CommonSuccessPopup, CommonUploadSuccessfull, CommonHoroPopup, CommonPhotoNewPopup, UploadHoroscopeViaPopup ,CommonRewardPopup, CommonClcPhotoPopup} from "../../CommonPopup";
import { Update_Notification_Status } from "../../../Helpers/api_helpers";
import * as Icons from '../../../Components/Kalyanlite/assets/InitialPopupImages';
import ParentsPhonePopupLite from "../../DashboardComponents/ParentsPhonePopupLite";
import { loginUser } from "../../../Store/Login/Action";
import NotificationIcon from "../../Kalyanlite/assets/Images/notificationbell.svg"
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from "../../../../src/firebase/firebaseConfig";
import { createPortal } from "react-dom";
import { getActivityTotalPoints, pointAwarded } from "../../../Store/KalyanRewards/Action";
import * as Images from "../../../Components/Kalyanlite/assets/images"
import { toast } from "react-toastify";
import { NavBarContext } from "../../../Contexts/NavBarContext";
import { getProfileMatch, getRecommendedMatches } from "../../../Store/KalyanLite/Action";
import ImageHoroscopePopup from "../../Edit Profile/ImageHoroscopePopup";
import { useTranslation } from "react-i18next";
import * as MatchSelector from "../../../Store/KalyanLite/selectors"

let popUpImageSrc;

function LiteLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [popupOpen, setPopupOpen] = useState()
  const [popUpContent, setPopupContent] = useState('')
  const [notificationId, setNotificationId] = useState()
  const [buttonName, setButtonName] = useState('');
  const [imageSrc, setImageSrc] = useState()
  const [title, setTitle] = useState()
  const [showParentNumPopup, setShowParentNumPopup] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const initialref = useRef(false)
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showcastepopup, setShowCastePopup] = useState(false)
  const [showFamilyCodePopup, setShowFamilyCodePopup] = useState(false)
  const [showTrailPackPopup, setShowTrailPackPopup] = useState(false)
  const [showTrailPackCompletePopup, setShowTrailPackCompletePopup] = useState(false)
  const [showNotificationPermission, setShowNotificationPermission] = useState(false)
  const [showRewardsPopup, setShowRewardsPopup] = useState(false)
  const [showRankingPopup, setShowRankingPopup] = useState(false)
  const [from, setFrom] = useState("");
  const [successPopup, setSuccessPopup] = useState(false);
  const [photopopupOpen, setPhotoPopupOpen] = useState(false);
  const [privacyPhotoPopupOpen, setPrivacyPhotoPopupOpen] = useState(false);
  const [showUploadHoroscope, setshowUploadHoroscope] = useState(false);
  const [isFrom, setIsFrom] = useState("");


  const { t: trans } = useTranslation();

  const { completeNow, clickAddHabits, setClickAddHabits,
    clickAddFamily, setClickAddFamily, clickAddAstro, setClickAddAstro,
    clickAddHoro, setClickAddHoro } = useContext(NavBarContext);
  // Access the passed state
  const trailPackPopup = location.state?.trailPackPopup;
  console.log(trailPackPopup,'trailPackPopup');

  const [starRaasiPopup, setStarRaasiPopup] = useState(false)
  const [showAddPhoto, setShowAddPhoto] = useState(false);
  const [showHoroPopup, setShowHoroPopup] = useState(false);

  const [successPopupData, setSuccessPopupData] = useState({ show: false, content: "" })
  const [showingSuccessPopup, setShowingSuccessPopup] = useState({ data: "", show: false })
  const [generatedHoroScopeUrl, setgeneratedHoroscopeurl] = useState({ url: "", flag: "", show: false });

  const [showUploadSuccessPopup, setshowUploadSuccessPopup] = useState(false)
  const showSuccessPopup = (content) => {
    setSuccessPopupData({ show: true, content })
  }

  const closeSuccessPopup = () => {
    setSuccessPopupData({ show: false, content: "" })
  }
  const { menuSelect, isPusherReceived, setIsPusherReceived, setCustomerTemplate } = useContext(NavBarContext)


  const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);
  const isV3User = localStorage.getItem("isV3User")


  const { ProfileData } = useSelector(state => ({
    ProfileData: state.DashBoardAPI?.ProfileData?.data
  }));

  const loggedInProfileStatus = ProfileData?.status

  let { communitySiteData } = useSelector(state => ({
    communitySiteData: state.Login?.communitySiteContent?.data
  }));

  const callBackPopupFun = (data) => {
    setShowingSuccessPopup({ data: data, show: false })
    setgeneratedHoroscopeurl({ url: data, flag: "GenerateHoroscope", show: true });
  }

  useEffect(() => {

    console.log("pusher count");

    const audio = new Audio(NotificationReceivedSound);
    // const channelName = `private-App.Models.User.1`;
    const channelName = `private-App.Models.User.${localStorage.getItem(
      Constants.loginLocalStorageKeys.chatLoginId,
    )}`;
    const customerCareChannelName = `private-user.${localStorage.getItem
      (Constants.loginLocalStorageKeys.chatLoginId,
      )}`;
    // console.log("channelName", channelName);

    const pusher2 = new Pusher("kalyan123", {
      wsHost: config.messengerUrl,
      wsPort: "443",
      wssPort: "443",
      cluster: "ap2",
      authEndpoint: `${config.api.chat_api}/broadcasting/auth`,
      enabledTransports: ["ws", "wss"],
      // enabledTransports: ["ws"],
      encrypted: false,
      // disableStats: true,
      forceTLS: false,
      auth: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Constants.loginLocalStorageKeys.chatLogin,
          )}`,
        },
      },
    });

    const channel = pusher2.subscribe(channelName);
    channel.bind(
      "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
      (data) => {
        console.log("SOCKET DATA New", data);
        console.log(data.message, "data.message");

        if (data.type === "App\\Notifications\\GroupChatMessage") {

          const sendId = Array.isArray(data?.message) ? data?.message[0].sender_id : data?.message?.sender_id

          console.log("sendId", sendId, getGroupAuthId(), Array.isArray(data?.message));

          if (!(sendId == getGroupAuthId())) {
            dispatch(groupMessageReceived(data.message));
            audio.play();
          }
          dispatch(getGroupChatList({ profileId: localStorage.getItem(Constants.loginLocalStorageKeys.profileId) }))
        } else if (data.type === "App\\Notifications\\ChatReaction") {

          if (!(data?.data?.reacted_by_id == getGroupAuthId())) {
            dispatch(reactionReceived(data))
          }

        } else if (data?.type === "App\\Notifications\\ChatReadStatus") {
          dispatch(readbyReceived(data.data))
        } else if (data?.type === "App\\Notifications\\SendPointsToUserEvent") {
          dispatch(pointAwarded())
        } else if (data?.type === "App\\Notifications\\SenderChatMessage") {
          // dispatch(messageReceived(data.message));
          dispatch(getChatList({
            page_size: Constants.matchRowCount,
            page: 1,
            isMessageFrom: "pushNotification"
          }))
          dispatch(
            getConversationList({
              receiver_id: data?.message?.receiver_profile_id,
              receiver_status: loggedInProfileStatus,
              isMessageFrom: "pushNotification"
            }),
          );
        }
        else {
          setIsPusherReceived(true)
          dispatch(messageReceived(data.message));
          dispatch(getChatList({
            page_size: Constants.matchRowCount,
            page: 1,
          }))
          if (data?.message?.is_fulfillment_processed) {
            setTimeout(() => {
              dispatch(
                getProfileMatch({
                  email: loginDetail()[0],
                  userId: loginDetail()[1],
                  viewprofileid: data?.message?.sender_profile_id,
                  status: "new",
                  callFrom: "MatchPopup",
                  loginFrom: getDeviceInfo(),
                  pageno: 1,
                }, logout),
              );
            }, 2000);
          }

          if (!data.is_muted) {
            audio.play();
          }
        }

      },
    );

    channel.bind("App\\Notifications\\ChatReaction", (data) => {
      console.log("SOCKET DATA ChatReaction", data);
    })


    // Customer care channel subscription
    const customerCareChannel = pusher2.subscribe(customerCareChannelName);
    customerCareChannel.bind("pusher:subscription_succeeded", () => {
      console.log(`Subscribed to customer care channel: ${customerCareChannelName}`);
    });

    customerCareChannel.bind("App\\Events\\SendMessageToCustomerEvent", (data) => {
      console.log("agent SOCKET DATA", data);
      localStorage.setItem(Constants.loginLocalStorageKeys.messageId, data?.message?.id)
      localStorage.setItem(Constants.loginLocalStorageKeys.admUserId, data?.message?.adm_users_id)
      localStorage.setItem(Constants.loginLocalStorageKeys.conversationId, data?.message?.conversation_id)
      dispatch(UnreadMsgCount({
        sender_id: localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
      }));
      audio.play().catch(e => console.error("Audio play error:", e));
      // dispatch(
      //   getCustomerServiceConversation({
      //     sender_id: localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
      //   }),
      // );
      if (data?.message?.message_type_id == 6) {
        setShowRankingPopup(true)
        setCustomerTemplate(false)

      }
      if (localStorage.getItem(Constants.loginLocalStorageKeys.customerChatClicked) === "true") {
        dispatch(
          getCustomerServiceConversation({
            sender_id: localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
          }),
        );
      }
      if (data?.message?.message_type_id == 6) {
        setShowRankingPopup(true)
      }
    });

    const showActivityFeedback = (data) => {
      dispatch(pointAwarded(data))
    }

    customerCareChannel.bind("App\\Events\\SendPointsToUserEvent", (data) => {
      console.log("SOCKET DATA", data);
      if (data?.activityId == 1) {
        showActivityFeedback(data)
      }
    });

    customerCareChannel.bind("App\\Events\\UserPointEvent", (data) => {
      console.log("SOCKET DATA", data);
      if (data?.activityId > 1) {
        showActivityFeedback(data)
        // dispatch(pointAwarded(data))
        // toast(
        //   <div className='flex items-center'>
        //     <img src={Images.KalyanCoin} className='w-10 h-10 mr-4 kalyan-coin' alt='' />
        //     {data?.messageData}
        //   </div>,
        //   {
        //     hideProgressBar: true,
        //     // onClose: () => {
        //     //     dispatch(hideCelebration())
        //     // },
        //     onClick: () => {
        //       navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[13].urlPath))
        //     }
        //   }
        // )
      }
    });


    return () => {
      pusher2.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(dashboardProfileData({}, logout));
    // dispatch(getActivityTotalPoints())
    if (localStorage.getItem("checkbox")) {
      if ((localStorage.getItem("LOGIN_FROM") == "/new/matches" || localStorage.getItem("LOGIN_FROM") == "/")) {
        if (sessionStorage.getItem("functionCalled") == null) {

          let name = localStorage.getItem(Constants.loginLocalStorageKeys.loginName) || localStorage.getItem(Constants.loginLocalStorageKeys.loginPhone);
          let password = localStorage.getItem("pw");

          dispatch(loginUser({ name, password }, navigate));

          sessionStorage.setItem("functionCalled", "true");
        }
      }
    }
  }, []);

  const updateDeviceToken = async (token) => {
    try {
      const request = {
        "webKey" : token
      };

      const { statusCode, data } = await POST(
        `${config.api.API_URL}${EndPoints.UpdateDeviceTokenUrl()}`,
        request
      );


      if (statusCode === 200) {
        if (data.data.status === "SUCCESS") {
        }

      } else if (statusCode === 401) {
        logout();
      }
    } catch (error) {
      console.error("Error fetching update device token data:", error);
    }
  };

  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await window.Notification.requestPermission();

    if (permission === "granted") {
      window.navigator.serviceWorker.register(process.env.PUBLIC_URL + '/firebase-messaging-sw.js', { scope: "/firebase-cloud-messaging-push-scope" }).then(async () => {
        const token = await getToken(messaging, {
          vapidKey: "BOkYKLBpw9iIkK7p3rSiRiOUvvaTj77ZBIueLdsSRy9BeiragVXvww6zR1IawhNGDhJjsxB0JolYTzBwqapVGyI",
        });

        localStorage.setItem(Constants.loginLocalStorageKeys.fcmToken, token)
        console.log("Token generated : ", token);
        updateDeviceToken(token)
        localStorage.setItem(Constants.loginLocalStorageKeys.showNotifyPopup,"true")
      })
      //We can send token to server
    } else if (permission === "denied") {
      localStorage.setItem(Constants.loginLocalStorageKeys.showNotifyPopup,"true")
      //notifications are blocked
      // alert("You denied for the notification");
    }
  }

  useEffect(() => {
    if("Notification" in window){
      if (window.Notification.permission !== "granted" &&  localStorage.getItem(Constants.loginLocalStorageKeys.showNotifyPopup) != "true") {
        setShowNotificationPermission(true)
      }else{
        // requestPermission()
      }

    }

  }, []);



  const closePermission = () => {
    requestPermission()
    setShowNotificationPermission(false)
    localStorage.setItem(Constants.loginLocalStorageKeys.showNotifyPopup,"true")
  }


  const fetchGroupData = async () => {
    const profileId = ProfileData?.profileId;
    if (!profileId) {
      // Handle the case when profileId is not available
      console.error('ProfileId not available');
      return;
    }

    const { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getfamilyUrl(profileId)}`
    );


    if (statusCode === 200) {
      if (data.status === 'Success') {
        console.log(data.data.groupMembers, 'groupMembers');
        if (data.data.groupMembers?.length > 0) {
          const relation = data.data.groupMembers?.filter(member => member?.parentsMobileNumber == localStorage.getItem(Constants.loginLocalStorageKeys.familyLoginMobileNumber))
          if (relation[0]?.relationshipName) {
            localStorage.setItem(Constants.loginLocalStorageKeys.familyLoginRelation, relation[0]?.relationshipName)
          }
        }
      }
    }
  }

  useEffect(() => {

    if (ProfileData) {
      fetchGroupData()
      localStorage.setItem(Constants.loginLocalStorageKeys.profileId, ProfileData?.profileId)
      localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, ProfileData?.status)
      localStorage.setItem(Constants.loginLocalStorageKeys.loginEmail, ProfileData?.usermailid ?? "")
      localStorage.setItem(Constants.loginLocalStorageKeys.loginUserName, ProfileData?.username)
      localStorage.setItem(Constants.loginLocalStorageKeys.loginPhone, ProfileData?.userphoneno)
      localStorage.setItem(Constants.loginLocalStorageKeys.loginCountryCode, ProfileData?.usercountrycode)
      localStorage.setItem(Constants.loginLocalStorageKeys.loginPhone, ProfileData?.userphoneno)
    }
  }, [ProfileData])



  // useEffect(() => {
  //   if(communitySiteData?.Subdomaindetails){
  //     document.title = communitySiteData?.Subdomaindetails[0]?.title
  //   }


  // }, [communitySiteData])


  const {
   
    initialMatchDataPopup
    
  } = useSelector(MatchSelector.getRecommendedMatches)

  const  ShowpopupData = initialMatchDataPopup?.currentMatch?.matchdata?.popup[0]?.showpopup

  useEffect(() => {
    if (initialref.current) return

    // if (ProfileData?.popup) {
    //   ProfileData.popup = [
    //     {
    //       "showpopup": "AddParentsNumber",
    //       "notificationId": 11428535,
    //       "content": "Dear (Profile Name), Please add your parents' number to complete your profile. Click here to add a parent's phone number."
    //     }


    //   ]
    // }
 
    if (ProfileData?.popup?.length > 0) {
      // ProfileData.popup[0] = {
      //   "showpopup": "ReadAndReply",
      //   "notificationId": 11428535,
      //   "content": "You have <7> unread messages. Click here to read and reply now."
      // }
      if (ProfileData?.popup[0]?.showpopup == 'upgrade' || ProfileData?.popup[0]?.showpopup == 'photo' || ProfileData?.popup[0]?.showpopup == 'paymentpagevisited') {
        setPopupOpen(false)
        setPhotoPopupOpen(false)
      }
      else {
        if (ProfileData?.popup[0]?.showpopup) {
          initialref.current = true
          setPopupContent(ProfileData?.popup[0]?.showpopup == 'PrivacyOption' ? trans('clcprivacy_popup.add_photo_to_your_profile_and') :
            ProfileData?.popup[0]?.showpopup == 'StarRaasi' ? "Profiles with Star & Raasi gets more responses. Add Star & Raasi details to your profile based on our recommendation or add your own. Add Star & Raasi Now!" :
              ProfileData?.popup[0]?.content)
          setNotificationId(ProfileData?.popup[0]?.notificationId)
          const src = Icons[ProfileData?.popup[0]?.showpopup]
          setImageSrc(src);
          
          setButtonName(trans(commonPopupButtonNames[ProfileData?.popup[0]?.showpopup]))
          setTitle(trans(commonPopupTitleNames[ProfileData?.popup[0]?.showpopup]))
          // setPopupContent('Add photo to your profile and take control of your privacy by using privacy options.')
          //   setNotificationId(ProfileData?.popup[0]?.notificationId)
          //   const src= Icons['PrivacyOption']
          //   setImageSrc(src);
          //   setButtonName(commonPopupButtonNames['PrivacyOption'])
          //   setTitle(commonPopupTitleNames['PrivacyOption'])
          if (isCommunitySite && ProfileData?.popup[0]?.showpopup == 'DownloadApp') {
            setPopupOpen(false)
          } 
          else if (ProfileData?.popup[0]?.showpopup == "TrialPack" || localStorage.getItem("popupFromApp")) {
            setShowTrailPackPopup(true)
            if(localStorage.getItem("popupFromApp")){
              localStorage.removeItem("popupFromApp");
              localStorage.setItem("AppPopup",true);
            }
          }
          else if(trailPackPopup == 'Y'){
            setShowTrailPackPopup(true)
          }
          else if (ProfileData?.popup[0]?.showpopup == "AddPhoto"){
            setPopupOpen(false)
            setPhotoPopupOpen(true)
          }
          else {
            setPopupOpen(true)
            setPhotoPopupOpen(true)
          }
        }
      }
    }

  }, [ProfileData])

  // useEffect(()=>{
  //   if(popupdata){
  //     initialref.current = true     
  //     setPopupContent("Add Horoscope")
  //     setNotificationId(909090)
  //     const src= Icons["Horoscope"]
  //     setImageSrc(src);
  //     setButtonName(commonPopupButtonNames["Horoscope"])
  //     setTitle(commonPopupTitleNames["Horoscope"])
  //     if(isCommunitySite && ProfileData?.popup[0]?.showpopup == 'DownloadApp'){
  //       setPopupOpen(false)
  //     }else{
  //       setPopupOpen(true)
  //     }
  //   }
  // },[popupdata])

  //check if family code is not setted, then show family code popup
  useEffect(() => {
    if (ProfileData?.familycode == "Y") {
      setShowFamilyCodePopup(true)
    }
  }, [ProfileData])

  //show kalyan rewards popup after 3min
  useEffect(() => {
    if (ProfileData?.showactivitypopup == "Y") {
      setTimeout(() => {
        setShowRewardsPopup(true)
      }, Constants.kalyanRewardsPopupWaitTime);
    }
  }, [ProfileData])


  const onPopupClose = async (data, param) => {
    if (param != "notification") {
      setPopupOpen(false)
    }
    const request = {
      "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      "userId": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
      "isClicked": data,
      "notificationId": notificationId ?? location?.state?.data?.notificationId,
      "clickButton": param == "addPhoto" ? "photo" : param == "viewMatches" ? "view matches" : undefined,
    }
    
    Update_Notification_Status(request);

    if (!data) {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId, notificationId)
    }

  }



  const redirectTo = ({ path, notiId }) => {
    console.log(path, 'pathpathpath');

    if (path == 'DownloadApp') {
      window.open(RouteSegments.staticLinkForAppDownload.android)
      return;
    }
    sessionStorage.setItem(Constants.loginLocalStorageKeys.notificationId, notiId)
    if (path == 'photo' ) {
      // navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), {
      //   state: { isFromInitialPopup: true },
      //  });
      setFrom(path);
      setShowAddPhoto(true)
    }
    if (path == 'AddPhoto' ) {
      // navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), {
      //   state: { isFromInitialPopup: true },
      //  });
      setFrom(path);
      setPhotoPopupOpen(true)
    }
    if ( path == "AddPhotoFromCLC") {
      // navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), {
      //   state: { isFromInitialPopup: true },
      //  });
      setFrom(path);
      setShowAddPhoto(true)
    }
    else if (path == 'PartnerPreference') {
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[1].urlPath), {
        state: { isFromInitialPopup: true },
      });
    } else if (path == 'Matches') {

    } else if (path == "StartMessage") {
      navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, { state: { from: "newConversations", isFromChat: true } })
    } else if (path == 'NewMatches') {
      partnerPrefSearchApi()
    } else if (path == "AddParentsNumber") {
      setPopupOpen(false)
      setShowParentNumPopup(true)
    } else if (path == "ReadAndReply"
      || path == "UnreadMessage"
      || path == "MsgSeenNotReply") {
      navigate(RouteSegments.CHAT_LITE, {
        state: { isFromInitialPopup: true },
      });
    }
    else if (path == "viewMatches") {

      let list = FilterOptions.filter(data => data.id == 8).map(data => ({ ...data, getRequest: null, isChecked: true }))
      sessionStorage.setItem(Constants.loginLocalStorageKeys.filterState, JSON.stringify({ isFrom: "clcpopup", selectedFilter: list }))
      navigate(RouteSegments.MATCH_LITE, { state: { isFrom: "clcpopup", selectedFilter: list } })

    }
    else if (path == "TrialPack") {
      console.log(path, "path");
      setShowTrailPackPopup(true)
      navigate(RouteSegments.MATCH_LITE, { replace: true })
    } else if (path == 'PrivacyOption') {
      // setShowAddPhoto(true)
      setPrivacyPhotoPopupOpen(true)
      // navigate(RouteSegments.MATCH_LITE, { replace: true })
    }
    else if (path == "viewMatches") {

      let list = FilterOptions.filter(data => data.id == 8).map(data => ({ ...data, getRequest: null, isChecked: true }))
      sessionStorage.setItem(Constants.loginLocalStorageKeys.filterState, JSON.stringify({ isFrom: "clcpopup", selectedFilter: list }))
      navigate(RouteSegments.MATCH_LITE, { state: { isFrom: "clcpopup", selectedFilter: list } })

    } else if (path == "WhoViewedMyprofile") {
      setPopupOpen(true)
      navigate(RouteSegments.MATCH_GROUP, { replace: true })
    }
    else if (path == "StarRaasi") {
      setPopupOpen(false)
      console.log(path, "pathname");
      setStarRaasiPopup(true)

      navigate(RouteSegments.MATCH_LITE, { replace: true })
    }
    else if (path == "Horoscope") {
      setShowHoroPopup(true)
      navigate(RouteSegments.MATCH_LITE, { replace: true })
    }

  }

  useEffect(() => {
    if (ProfileData) {
      // setShowReligiousMigrationPopup(ProfileData?.showcastemigration === "Y");
      setShowCastePopup(ProfileData?.cleanClearExecution === true)
    }
  }, [ProfileData]);

  useEffect(() => {
    //CLC
    const path = searchParams.get("path")
    const notiId = searchParams.get("notificationId")
    const loginUrl = sessionStorage.getItem(Constants.loginLocalStorageKeys.loginUrl);
    console.log(path, 'path123');


    if (path && notiId) {
      searchParams.delete("path")
      searchParams.delete("notificationId")
      searchParams.delete("isNew")
      sessionStorage.setItem(Constants.loginLocalStorageKeys.notificationPath, path)
      setSearchParams(searchParams, { replace: true })
      onPopupClose(true);
      redirectTo({
        path,
        notiId
      })
    } else if (loginUrl?.includes("pp_matches") || searchParams.get("pp_matches") == "Y") {
      partnerPrefSearchApi()
    } else if (searchParams.get("WhoViewedMyprofile") == "Y" && location?.state?.isFrom == "chromenotif") {
      // searchParams.delete("WhoViewedMyprofile")
      // setSearchParams(searchParams, { replace: true })
      onPopupClose(true, "notification");
      setPopupContent(location?.state?.data?.notificationContent ?? "")
      setNotificationId(location?.state?.data?.notificationId)
      setImageSrc(Images.EyeIcon);
      setButtonName("WhoViewedMyprofile")
      setTitle("Add Photo & View Matches")
      setPopupOpen(true)
      setPhotoPopupOpen(true)
    }
    else if(searchParams.get("photo")){
      setShowAddPhoto(true)
    }
  }, [searchParams])

  useEffect(() => {

    if (ProfileData) {
      if (searchParams.get("WhoViewedMyprofile") == "Y") {
        if (ProfileData?.imageState != "A") {
          setPopupContent(location?.state?.data?.notificationContent)
          setNotificationId(location?.state?.data?.notificationId)
          setImageSrc(Images.EyeIcon);
          setButtonName("WhoViewedMyprofile")
          setTitle("Add Photo & View Matches")
          setPopupOpen(true)
          setPhotoPopupOpen(true)
        }

      }
    }
    else if(searchParams.get("photo") == "Y"){
      setShowAddPhoto(true)
     
    }

  }, [searchParams, ProfileData])

  useEffect(() => {

    if (location?.state?.isFrom == "listnotification") {
      onPopupClose(true, "notification");
      setPopupContent(location?.state?.data?.notificationContent)
      setNotificationId(location?.state?.data?.notificationId)
      setImageSrc(Images.EyeIcon);
      setButtonName("WhoViewedMyprofile")
      setTitle("Add Photo & View Matches")
      setPopupOpen(true)
      setPhotoPopupOpen(true)
    }

  }, [location])





  const partnerPrefSearchApi = async () => {
    let request = {
      email: loginDetail()[0] ?? null,
      userId: loginDetail()[1],
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.partnerpreferencesearch()}`,
      request
    );

    if (statusCode === 200) {

      const locState = {
        isFrom: "basicAdvanceSearch",
        menu: "savepreferences",
        searchTerms: data?.data,
        ppedited: false,
        isBasicSearch: false,
        loading: true
      }
      sessionStorage.setItem(Constants.loginLocalStorageKeys.filterState, JSON.stringify(locState))
      navigate(RouteSegments.MATCH_LITE, {
        state: locState,
        replace: true,
      });


    } else if (statusCode === 401) {
      logout();
    }
  }


  if (!isV3User) {
    return <Navigate to={RouteSegments.DASHBOARD} replace={true} />
  }


  const onPopupBtnClick = (param) => {
    onPopupClose(true, param);
    redirectTo({
      path: param == "addPhoto" ? "AddPhotoFromCLC" : param == "viewMatches" ? "viewMatches" 
      : param =="AddPhoto"?"CLC_AddPhoto":  param =="AddWhatsapp"?"CLC_Whatsapp":
      param =="Addfacebook"?"CLC_Facebook": ProfileData?.popup[0]?.showpopup,
      notiId: ProfileData?.popup[0]?.notificationId ?? sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId)
    });
  }

  const onRewardsPopupClose = () => {
    setShowRewardsPopup(false)
  }

  const closePhotoPopup = () => {
    setShowAddPhoto(false)
  }

  const buttonClick = () => {
    let list = FilterOptions.filter(data => data.id == 8).map(data => ({ ...data, getRequest: null, isChecked: true }))
    sessionStorage.setItem(Constants.loginLocalStorageKeys.filterState, JSON.stringify({ isFrom: "clcpopup", selectedFilter: list }))
    navigate(RouteSegments.MATCH_LITE, { state: { isFrom: "clcpopup", selectedFilter: list } })
    setSuccessPopup(false);
  }



  const onPhotoPopupClose = async (data ,param) => {
    const request = {
      "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
      "userId": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
      "isClicked": data,
      "notificationId": notificationId,
      "clickButton" : param =="AddPhoto" ? "CLC_AddPhoto":param =="Addfacebook"?"CLC_Facebook": param =="AddWhatsapp"?"CLC_Whatsapp":""
    }
    Update_Notification_Status(request);

    if (!data) {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId, notificationId)
    }

  }
 

  return (
    <div>
      {showRankingPopup &&
        <RankingStartPopup
          close={() => setShowRankingPopup(false)}
        />}

      {
        successPopup && (
          <CommonSuccessPopup
            close={() => {
              setSuccessPopup(false);
            }}
            title={"Photo Uploaded Successfully"}
            content={""}
            from={from}
            buttonClick={buttonClick}
          />
        )
      }
      {starRaasiPopup &&
        <AstroPopup OnClose={() => setStarRaasiPopup(false)} />}


      {/* 
      {
        showAddPhoto && <UploadPhotoPopuplite
          close={closePhotoPopup}
          title={"Add Photo"}
          callBack={() => {
            showSuccessPopup("Image uploaded succesfully")
          }}
          isFrom={"contentcard"}
          callBackReload={() => { }}
          setshowUploadSuccessPopup={setshowUploadSuccessPopup}
          PrivacyOptionTitle={commonPopupTitleNames[ProfileData?.popup[0]?.showpopup]}
        />
      } */}

      {
        showAddPhoto && <UploadPhotoPopuplite
          close={closePhotoPopup}
          title={trans('photo_popup.add_photo')}
          callBack={() => {
            showSuccessPopup("Image uploaded succesfully")
          }}
          isFrom={"contentcard"}
          callBackReload={() => { }}
          from={from}
          clcClick={() => {
            if (from == "AddPhotoFromCLC") {
              setSuccessPopup(true);
            }
          }}
        />
      }
      {
        showRewardsPopup && createPortal(
          <KalyanRewardsPopup close={onRewardsPopupClose} />,
          document.body
        )
      }
      {
        showAddPhoto && <UploadPhotoPopuplite
          close={closePhotoPopup}
          title={trans('photo_popup.add_photo')}
          callBack={() => {
            showSuccessPopup("Image uploaded succesfully")
          }}
          isFrom={"contentcard"}
          callBackReload={() => { }}
          from={from}
          clcClick={() => {
            if (from == "AddPhotoFromCLC") {
              setSuccessPopup(true);
            }
          }}
          setshowUploadSuccessPopup={setshowUploadSuccessPopup}
          PrivacyOptionTitle={trans(commonPopupTitleNames[ProfileData?.popup[0]?.showpopup]) ? trans(commonPopupTitleNames[ProfileData?.popup[0]?.showpopup]) : trans('clcprivacy_popup.add_photo_with_privacy_options')}
        />
      }
      {
        showUploadSuccessPopup && (
          <CommonUploadSuccessfull close={() => setshowUploadSuccessPopup(false)} notificationId={ProfileData?.popup[0]?.notificationId} />
        )
      }

      {
        showHoroPopup &&
        <CommonHoroPopup 
          isFrom={"clcRedirection"} 
          setShowingSuccessPopup={setShowingSuccessPopup} 
          notifyId={notificationId} 
          OnClose={(type,isFrom) => { 
            if(type == "upload_via"){
              setshowUploadHoroscope(true);
              setIsFrom(isFrom)
            }
            setShowHoroPopup(false)
          }}
        /> 
      }
      {
        showUploadHoroscope &&
        <UploadHoroscopeViaPopup
          close={() => setshowUploadHoroscope(false)}
          setShowingSuccessPopup={setShowingSuccessPopup}
          isFrom={isFrom} 
        />
      }

      {
        showingSuccessPopup?.show &&
        <CommonSuccessPopup
          close={() => {
            setShowingSuccessPopup((prev => ({ ...prev, show: false })));
          }}
          // title={trans('photos.success')}
          isFrom={"clcRedirection"}
          content={"Horoscope Added Successfully"}
          buttonName={trans('chat.view_horoscope')}
          callBackPopupFun={() => callBackPopupFun(showingSuccessPopup?.data)}
        />
      }

      {
        generatedHoroScopeUrl.show ? (
          <ImageHoroscopePopup
            console={console.log("123456")}
            close={() => setgeneratedHoroscopeurl(prev => ({ ...prev, show: false }))}
            title={trans('chat.view_horoscope')}
            deleteImage={true}
            isShowEditBtn={false}
          >
            {generatedHoroScopeUrl.flag === "GenerateHoroscope" ? (
              <div className="relative">
                <iframe
                  title={trans('view_profile.horoscope')}
                  src={generatedHoroScopeUrl.url}
                  className="w-[70vw] lg:w-[60vw] h-[50vh]"
                />
                {/* {showHoroScopeLoader === true? <div className="absolute transform -translate-x-1/2 -translate-y-1/2 maxabsolute top-1/2 left-1/2"><Loader /></div>:null} */}
              </div>
            ) : (
              <img
                alt=""
                src={generatedHoroScopeUrl.url}
                className="w-[70vw] lg:w-[60vw] h-[50vh]"
              />
            )}
          </ImageHoroscopePopup>
        ) : (
          <></>
        )
      }

      {
        showRewardsPopup && createPortal(
          <KalyanRewardsPopup close={onRewardsPopupClose} />,
          document.body
        )
      }
      {/* {showFamilyCodePopup &&
        <FamilyCodePopup close={()=>setShowFamilyCodePopup(false)} />
      } */}
      {
        ProfileData?.popup[0]?.showpopup == "TrialPack" ||  ProfileData?.popup[0]?.showpopup == "AddPhoto"? <></> : popupOpen &&
          <CommonNewPopup closePopup={() => onPopupClose(false)} message={popUpContent}
            buttonClick={onPopupBtnClick} buttonName={buttonName} title={title} image={imageSrc} />
      }

      {/* {
        (photopopupOpen && ProfileData?.popup[0]?.showpopup == "AddPhoto") &&
          <CommonPhotoNewPopup closePopup={() => setPhotoPopupOpen(false)} message={popUpContent}  onPhotoPopupClose={onPhotoPopupClose}
            buttonClick={onPopupBtnClick} buttonName={buttonName} title={"Add Photo to Your Profile"} image={imageSrc}  isFrom="AddPhoto" />
      } */}

      { 
        ((privacyPhotoPopupOpen && ProfileData?.popup[0]?.showpopup == "PrivacyOption") || (photopopupOpen && ProfileData?.popup[0]?.showpopup == "AddPhoto")) &&
          <CommonClcPhotoPopup 
          closePopup={() => {
            if(ProfileData?.popup[0]?.showpopup == "AddPhoto"){
              setPhotoPopupOpen(false)
            }else{
              setPrivacyPhotoPopupOpen(false)
            }
          }} 
          message={popUpContent}  
          onPhotoPopupClose={onPhotoPopupClose}
          setshowUploadSuccessPopup={setshowUploadSuccessPopup}
          buttonClick={onPopupBtnClick} buttonName={buttonName} title={ProfileData?.popup[0]?.showpopup == "PrivacyOption" ? "Add Photo with Privacy Options" : "Add Photo"} image={imageSrc}  isFrom="AddPhoto" />
      }

      {
        showcastepopup && ProfileData?.cleanClearExecution == true &&
        <CommonCastPopup
          profileDate={ProfileData}

          close={() => setShowCastePopup(false)} />
      }

      {
        showNotificationPermission &&
        <CommonNotificationAlertPopup
          icon={NotificationIcon}
          close={closePermission}
          content={<span>
          Get updates on your profile and matches from Kalyan Matrimony. <br />
          Click Allow to get updates
          </span>}
          isFrom = {"notification"}
          NotificatiorequestPermission={()=>{requestPermission()}}
        />
      }

      {
        (showTrailPackPopup && location?.pathname == RouteSegments.MATCH_LITE) &&

        <CommonQualityPopup OnClose={() => setShowTrailPackPopup(false)} />
      }

      {
        (showTrailPackCompletePopup && location?.pathname == RouteSegments.MATCH_LITE) &&

        <CommonRewardPopup OnClose={() => setShowTrailPackCompletePopup(false)} />
      }
      {
          showParentNumPopup && (<ParentsPhonePopupLite
          close={() => setShowParentNumPopup(false)}
          title={trans('my_profile.add_parents_phone_number')}
          notificationId={ProfileData?.popup[0]?.showpopup == "AddParentsNumber"}
          callBackApply={() => { /* Add your callback logic here if needed */ }}
          parentsIsdCode={ProfileData?.parentsIsdCode}
          parentsMobileNumber={ProfileData?.parentsMobileNumber}
          isFrom={"clcRedirection"}
        />
        )
      }

      <div className='font-Poppins-Regular bg-[#eae6df] h-full md:h-screen w-screen overflow-y-hidden overflow-hidden fixed'>
        <div className='hidden md:block w-full h-[8.7rem] md:h-0 lg:h-[9rem] bg-[#d10a11] absolute top-0' />
        <div className='flex w-full h-full overflow-x-hidden overflow-y-hidden md:items-center md:justify-center'>
          <div className='overflow-hidden z-[1]  flex flex-col items-center justify-center'>

            <div className='w-[100vw] xl:w-[86vw] lg:w-[95vw]'>
              <LiteNavBar isFrom='conversation' />
            </div>
            <div className=' overflow-hidden  overflow-x-hidden  w-[100vw] h-full grow xl:w-[86vw] lg:w-[95vw] md:h-[93vh] xl:h-[82vh] 2xl:h-[85vh]  lg:h-[80vh]'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default LiteLayout;
